let _level = 0;
let _prefix: string | undefined;

export const LogLevel = {
  OFF: 10,
  DEBUG: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
  FATAL: 5,
};

export const setLogLevel = (level: number): void => {
  _level = level;
};

export const setLogPrefix = (prefix: string | undefined): void => {
  _prefix = prefix;
};

export const log = {
  debug: (message?: any, ...optionalParams: any[]) => {
    output(LogLevel.DEBUG, message, ...optionalParams);
  },
  info: (message?: any, ...optionalParams: any[]) => {
    output(LogLevel.INFO, message, ...optionalParams);
  },
  warning: (message?: any, ...optionalParams: any[]) => {
    output(LogLevel.WARNING, message, ...optionalParams);
  },
  // tslint:disable-next-line:object-literal-sort-keys
  error: (message?: any, ...optionalParams: any[]) => {
    output(LogLevel.ERROR, message, ...optionalParams);
  },
  fatal: (message?: any, ...optionalParams: any[]) => {
    output(LogLevel.FATAL, message, ...optionalParams);
  },
};

const consoleMethod: {[key: string]: string} = {
  [LogLevel.DEBUG.toString()]: 'log',
  [LogLevel.INFO.toString()]: 'info',
  [LogLevel.WARNING.toString()]: 'warn',
  [LogLevel.ERROR.toString()]: 'error',
  [LogLevel.FATAL.toString()]: 'error',
};

const output = (level: number, message?: any, ...optionalParams: any[]) => {
  if (_level <= level) {
    try {
      const method: string = consoleMethod[level.toString()];
      // eslint-disable-next-line @typescript-eslint/ban-types
      const func: Function = (console as any)[method] as Function;
      const m = _prefix ? [_prefix, message] : [message];
      if (optionalParams.length > 0) {
        m.push(...optionalParams);
      }
      func(...m);
    } catch (err) {
      // noop
    }
  }
};

export default log;
