import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

import {Auth, getAuth} from 'firebase/auth';
import {config} from './config/config-chancer-development';

// Initialize Firebase
const firebaseApp = firebase.initializeApp(config.firebaseConfig);

if (config.environment === 'local') {
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.auth().useEmulator('http://localhost:9099');
  firebase.storage().useEmulator('localhost', 9199);
}

let firebaseAuth: Auth;
export const getFirebaseAuth = () => {
  if (!firebaseAuth) {
    firebaseAuth = getAuth(firebaseApp);
  }
  return firebaseAuth;
};
